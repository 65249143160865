
import router from '@/router/clean';
import { defineComponent, onMounted, computed, ref, getCurrentInstance } from 'vue';
import { details, constant, common } from '@/store/stateless';
import Customers from '@/core/services/Callable/Customers';
import Etcs from '@/core/services/Callable/Etcs';
import { store } from '@/store';
import { UserTypesMixin } from '@/core/plugins/vcMixins';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { customer_details } from '@/core/utils'
import EmptyLead from '@/views/Customers/CustomerDetails/EmptyLead.vue'

export default defineComponent({
    components: {
        EmptyLead,
    },
    setup() {

        const user_id = computed(() => {
            // return store.getters.myId;
            let userId = window.localStorage.getItem('uid');
            if(!userId){
                userId = store.getters.myId;
            }
            return userId;
        });

        const customer_accordion = ref()

        const interestedPolicyModal = computed(() => {
            return store.state.invoice.openInterestedPolicyModal;
        });

        const permissions = computed(() => {
            return store.getters.getUserPermissions
        })

        const loading = computed(() => {
            return store.state.customer.detailLoading;
        })

        const customer_update_modal = ref()
        const customer_update_other_contact_modal = ref()

        const { view_task, view_policies, upload_documents } = constant

        const openModal = () => {
            const { customer_id, name, phone_country_code, phone_number, email, dob, gender,is_married, nationality_id, is_salary,other_contact_info } = cd.value
            console.log('phone_number',is_married)
            Customers.setHealth({
                id: customer_id,
                name,
                phone_country_code,
                phone_number,
                email,
                dob,
                gender,
                is_married,
                nationality_id,
                is_salary,
                other_contact_info
            })
            customer_update_modal.value.modal(true)
        }

        const openUpdateOtherContactModal = () => {
            customer_update_other_contact_modal.value.modal(true)
        }

        const cd = computed(() => {
            return store.getters.getCustomer
        })

        const lead_count = computed(() => {
            return store.getters.getLeadCounts
        })

        onMounted(async () => {
            setCurrentPageBreadcrumbs("Customer Details", [{ label: "Leads & Tasks", value: 'CustomerLists' }]);

            await CustomerDetails();
            await Etcs.getSettingVal({type:'hl_renewal_policy_days'})
            await Etcs.getSettingVal({type:'hl_q_lead_converted_days'})
        })

        const inst = getCurrentInstance()
        const tabClick = (value: string) => {
            switch (value) {
                case 'tasks': openTasks(); break
                case 'documents':
                    // upload_documents.lead_id = 0;
                    // upload_documents.disabled = false;
                    clickModal(value);
                    break
                case 'policies': openPolicies(value); break
                default: clickModal(value); break
            }

        }

        const CustomerDetails = async (is_loaded = false) => {
            const params = { customer_id: parseInt(router.currentRoute.value.params.customer_id.toString()) }
            if (is_loaded) {
                params['is_loaded'] = true
            }

            const data = await Customers.details(params)

            if(is_loaded){
                const listRef: any = document.getElementById('accordian_0');
                listRef.click()

                setTimeout(() => {
                    const listRef: any = document.getElementById('accordian_0');
                    listRef.click()
                }, 1400);
            }

            // if (data.response.status == 422) {
            //     router.push({
            //         name: "CustomerLists"
            //     });
            // }
            // await Customers.getCustomerById(params)
        }

        const openTasks = () => {
            view_task.open = true
            view_task.customer_id = cd.value.customer_id
            view_task.is_fullscreen = true
        }

        const openPolicies = (value) => {
            view_policies.open = true
            view_policies.customer_id = cd.value.customer_id
            view_policies.is_fullscreen = true

            clickModal(value)
        }

        const clickModal = (value) => {
            const item: any = inst?.refs[value]
            if (item !== undefined)
                item.modal(true)
        }

        const removeDocument = () => {
            store.state.policy.policy_documents.open = false
            setTimeout(() => {
                store.commit('SET_DOCUMENT_LISTS', [])
            }, 100);
        }

        
        function toLeads() {
            router.push({ name: 'CustomerLists' })
        }

        async function reOpen(data) {
            const params = { 
                customer_id: parseInt(router.currentRoute.value.params.customer_id.toString()),
                is_loaded: true
             }
             customer_accordion.value.resetTask()
            await Customers.details(params)
            customer_accordion.value.recallData(data)
        }

        return {
            customer_accordion,
            reOpen,
            removeDocument,
            cd,
            common,
            details,
            loading,
            lead_count,
            customer_details,
            customer_update_modal,
            CustomerDetails,
            UserTypesMixin,
            openModal,
            tabClick,
            customer_update_other_contact_modal,
            openUpdateOtherContactModal,
            clickModal,
            store,
            permissions,
            interestedPolicyModal,
            toLeads,
            user_id
        }
    },
})
