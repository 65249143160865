import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/images/edit-main.svg'


const _hoisted_1 = {
  key: 1,
  class: "card"
}
const _hoisted_2 = {
  key: 0,
  class: "card p-0 card-customer-details"
}
const _hoisted_3 = { class: "card-header card-customer-details-custom w-100" }
const _hoisted_4 = { class: "card-title m-0 fs-6 mt-2 w-100 card-title-block-custom pt-2 pb-2" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = {
  key: 0,
  class: "bi bi-flag-fill text-danger me-1"
}
const _hoisted_7 = {
  key: 2,
  class: "call-inbox-block cursor-pointer"
}
const _hoisted_8 = {
  key: 1,
  src: "/media/background/call-inbox.svg",
  alt: "",
  class: "call-inbox-block cursor-pointer"
}
const _hoisted_9 = {
  key: 3,
  class: "text-truncate truncs w-auto fw-light text-gray-700 text-truncate task-username box-item fst-italic"
}
const _hoisted_10 = {
  key: 0,
  class: "error"
}
const _hoisted_11 = { class: "card-footer" }
const _hoisted_12 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_socket = _resolveComponent("vc-socket")!
  const _component_vc_modal_customer_update = _resolveComponent("vc-modal-customer-update")!
  const _component_vc_customer_merge_lead = _resolveComponent("vc-customer-merge-lead")!
  const _component_vc_customer_view_details = _resolveComponent("vc-customer-view-details")!
  const _component_vc_customer_view_tasks_drawer = _resolveComponent("vc-customer-view-tasks-drawer")!
  const _component_vc_customer_view_notes = _resolveComponent("vc-customer-view-notes")!
  const _component_vc_customer_view_emails = _resolveComponent("vc-customer-view-emails")!
  const _component_vc_customer_view_sms = _resolveComponent("vc-customer-view-sms")!
  const _component_vc_customer_view_policies = _resolveComponent("vc-customer-view-policies")!
  const _component_vc_customer_view_customer_log = _resolveComponent("vc-customer-view-customer-log")!
  const _component_vc_customer_view_lead_activity = _resolveComponent("vc-customer-view-lead-activity")!
  const _component_vc_customer_view_documents = _resolveComponent("vc-customer-view-documents")!
  const _component_vc_customer_view_quotes = _resolveComponent("vc-customer-view-quotes")!
  const _component_vc_lead_declarations = _resolveComponent("vc-lead-declarations")!
  const _component_vc_update_other_contact_info = _resolveComponent("vc-update-other-contact-info")!
  const _component_vc_policy_documents = _resolveComponent("vc-policy-documents")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_customer_tabs = _resolveComponent("vc-customer-tabs")!
  const _component_vc_customer_accordion = _resolveComponent("vc-customer-accordion")!
  const _component_EmptyLead = _resolveComponent("EmptyLead")!
  const _component_vc_interested_policy_modal = _resolveComponent("vc-interested-policy-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_vc_socket, {
        channel: `health-channel-${_ctx.user_id}`,
        onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CustomerDetails(true)))
      }, null, 8, ["channel"]),
      (_ctx.cd.customer_id > 0)
        ? (_openBlock(), _createBlock(_component_vc_socket, {
            key: 0,
            channel: `notif-invoice-health-${_ctx.cd.customer_id}`,
            listen: "GlobalNotificationToAppEvent",
            onAction: _ctx.reOpen
          }, null, 8, ["channel", "onAction"]))
        : _createCommentVNode("", true),
      _createVNode(_component_vc_modal_customer_update, {
        ref: "customer_update_modal",
        onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CustomerDetails(true)))
      }, null, 512),
      _createVNode(_component_vc_customer_merge_lead, {
        onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CustomerDetails(true)))
      }),
      _createVNode(_component_vc_customer_view_details, { ref: "view_details" }, null, 512),
      _createVNode(_component_vc_customer_view_tasks_drawer, { ref: "task" }, null, 512),
      _createVNode(_component_vc_customer_view_notes, { ref: "notes" }, null, 512),
      _createVNode(_component_vc_customer_view_emails, { ref: "emails" }, null, 512),
      _createVNode(_component_vc_customer_view_sms, { ref: "sms" }, null, 512),
      _createVNode(_component_vc_customer_view_policies, { ref: "policies" }, null, 512),
      _createVNode(_component_vc_customer_view_customer_log, { ref: "customer_log" }, null, 512),
      _createVNode(_component_vc_customer_view_lead_activity, { ref: "lead_activity" }, null, 512),
      _createVNode(_component_vc_customer_view_documents, {
        ref: "documents",
        onUpdate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.CustomerDetails(true)))
      }, null, 512),
      _createVNode(_component_vc_customer_view_quotes, { ref: "view_quotes" }, null, 512),
      _createVNode(_component_vc_lead_declarations, {
        ref: "declarations",
        onUpdate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CustomerDetails(true)))
      }, null, 512),
      _createVNode(_component_vc_update_other_contact_info, {
        ref: "customer_update_other_contact_modal",
        onUpdate: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CustomerDetails(true)))
      }, null, 512),
      _createVNode(_component_vc_policy_documents, {
        ref: "view_documents",
        onClose: _ctx.removeDocument,
        "is-view": ""
      }, null, 8, ["onClose"]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[8] || (_cache[8] = [
            _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...", -1)
          ])))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            ( Object.keys(_ctx.cd).length > 0 && _ctx.lead_count != 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h3", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer_details, (customer, i) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                          _createElementVNode("span", _hoisted_5, [
                            (customer.isEdit && _ctx.cd.hot_enquiry)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                              : _createCommentVNode("", true),
                            (customer.isBadge)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 1,
                                  class: "badge",
                                  style: _normalizeStyle(`${_ctx.UserTypesMixin(_ctx.cd.customer_status_id)}`)
                                }, _toDisplayString(_ctx.cd[customer.value]), 5))
                              : _createCommentVNode("", true),
                            (customer.isOtherInfo)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                  (_ctx.cd[customer.value])
                                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                        key: 0,
                                        class: "box-item",
                                        effect: "light",
                                        content: _ctx.cd[customer.value],
                                        placement: "top"
                                      }, {
                                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                                          _createElementVNode("img", {
                                            src: "/media/background/call-inbox.svg",
                                            alt: "",
                                            class: "call-inbox-block cursor-pointer"
                                          }, null, -1)
                                        ])),
                                        _: 2
                                      }, 1032, ["content"]))
                                    : (_openBlock(), _createElementBlock("img", _hoisted_8))
                                ]))
                              : _createCommentVNode("", true),
                            (customer.isOtherInfo === undefined && customer.isBadge === undefined && customer.isClick === undefined)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                  (_ctx.common.getTextWidth(_ctx.cd[customer.value]) > 120)
                                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                        key: 0,
                                        class: "box-item",
                                        effect: "light",
                                        content: _ctx.cd[customer.value],
                                        placement: "bottom",
                                        "show-after": 500,
                                        "hide-after": 0
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.cd[customer.value]), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["content"]))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createTextVNode(_toDisplayString(customer.label) + " ", 1),
                                        (customer.value == 'email' && !_ctx.cd[customer.value])
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Update Email "))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              _createTextVNode(_toDisplayString(_ctx.cd[customer.value]), 1)
                                            ], 64))
                                      ], 64))
                                ]))
                              : _createCommentVNode("", true),
                            (customer.isClick && (_ctx.permissions.is_admin || _ctx.permissions.is_manager || _ctx.permissions.is_qca))
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 4,
                                  class: "fs-6 cursor-pointer",
                                  style: {"font-style":"italic","font-weight":"200"},
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.tabClick('customer_log')))
                                }, _toDisplayString(customer.label), 1))
                              : _createCommentVNode("", true),
                            (customer.isEdit)
                              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                  key: 5,
                                  class: "box-item",
                                  effect: "light",
                                  content: "Edit",
                                  placement: "bottom",
                                  "show-after": 500
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("i", {
                                      class: "la la-edit ms-2 cursor-pointer box-item",
                                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
                                    }, _cache[10] || (_cache[10] = [
                                      _createElementVNode("img", {
                                        src: _imports_0,
                                        alt: "Edit"
                                      }, null, -1)
                                    ]))
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.customer_details.length !== (i + 1))
                            ? (_openBlock(), _createBlock(_component_el_divider, {
                                key: 0,
                                direction: "vertical"
                              }))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_vc_customer_tabs, {
                      lead_count: _ctx.lead_count,
                      customer_id: _ctx.cd.customer_id,
                      onTab: _ctx.tabClick,
                      disabled: _ctx.store.getters.getTabDisabled
                    }, null, 8, ["lead_count", "customer_id", "onTab", "disabled"]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_vc_customer_accordion, {
                        onTab: _ctx.clickModal,
                        ref: "customer_accordion"
                      }, null, 8, ["onTab"])
                    ])
                  ])
                ]))
              : (_openBlock(), _createBlock(_component_EmptyLead, {
                  key: 1,
                  message: Object.keys(_ctx.cd).length > 0 && _ctx.lead_count == 0 ? 'This Customer does not have any leads in Health, may have leads in Car or Misk.' : 'Customer details not available',
                  imagePath: `/media/background/${Object.keys(_ctx.cd).length > 0 && _ctx.lead_count == 0 ? 'lead-not-found' : 'customer-details-not-found'}.svg`,
                  buttonText: "Go back to listing page",
                  onClick: _ctx.toLeads
                }, null, 8, ["message", "imagePath", "onClick"]))
          ], 64))
    ]),
    (_ctx.interestedPolicyModal)
      ? (_openBlock(), _createBlock(_component_vc_interested_policy_modal, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}